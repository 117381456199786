.wrapper {
  padding: 5px 20px;
  background-color: rgba(160, 225, 223, 0.377);
}

.wrapper fieldset {
  margin: 20px 0;
}
* {
  box-sizing: border-box;
}

body {
  font: 16px Arial;
  color: #000;
}

a {
  color: var(--blue);
}

.nav-link-bold {
  font-weight: 700;
  color: #FFF;
}


.nav-link {
  font-weight: 700;
  color: #FFF;
}

.nav-link:hover {
  cursor: pointer;
}

/*the container must be positioned relative:*/
.autocomplete {
  position: relative;
  display: inline-block;
}

input {
  border: 1px solid transparent;
  background-color: #f1f1f1;
  padding: 10px;
  font-size: 16px;
}

input[type=text] {
  background-color: #f1f1f1;
  width: 100%;
}

input[type=submit] {
  background-color: DodgerBlue;
  color: #fff;
  cursor: pointer;
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}

.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff; 
  border-bottom: 1px solid #d4d4d4; 
}

/*when hovering an item:*/
.autocomplete-items div:hover {
  background-color: #e9e9e9; 
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
  background-color: DodgerBlue !important; 
  color: #ffffff; 
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.message-tab {
  border: 2px solid #000;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 10px 0;
}

.message-left {
  border: 2px solid #ccc;
  background-color: #ddd;
  border-radius: 5px;
  padding: 5px;
  padding-left: 10px;
  margin: 10px 0;
}

.message-right {
  border: 2px solid #dedede;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 5px;
  padding-left: 40px;
  margin: 10px 0;
}

.message-icon {
  margin-right: 5px
}

.message-reply {
  border: 2px solid #000;
  background-color: #fff;
  border-radius: 5px;
  padding: 5px;
  padding-left: 10px;
  margin: 10px 0;
};

.message-time {
  float: right;
  font: 12px Arial;
}

select {
  border: 1px solid transparent;
  background-color: #f1f1f1;
  padding: 10px;
  font-size: 16px;
}

/* .imageRow {
  display: flex;
} */

.imageCol {
  float: left; 
  flex: 25%;
  padding: 5px;
}

.imageInput {
  border: 1px solid #000; 
  padding: 2px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 550;
}

.imageInputLeft {
  padding: 10px 5px 10px 0px;
}

.imageInputRight {
  padding: 0px 10px;
  background-color: #FFF;
}

.imageInputWrapper {
  float: left;
  display: flex;
}

.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 500px;
  width: 500px;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.wrap {
  display: flex;
}

.wrapLeft {
  float: left;
}

.wrapRight {
  float: right;
}

.spaceRight { 
  padding-right: 10px;
}

.select-all-text {
  user-select: all;
  -moz-user-select: all;
  -webkit-user-select: all;
  }